<template>
    <!-- 我的优惠券 -->
    <div class="customer_box">
        <div style="  position: relative;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="可使用" name="0"></el-tab-pane>
                <el-tab-pane label="已使用" name="1"></el-tab-pane>
                <el-tab-pane label="已失效" name="2"></el-tab-pane>
            </el-tabs>
            <div class="coupon_btn" @click="goCouponDetail">领券中心</div>
        </div>
        <div class="list_box" v-if="couponList.length">
            <div class="list_item" :class="{disabled: activeName != '0'}" v-for="(item,index) in couponList" :key="index">
                <div class="item_left">
                    <span v-if="item.couponType==1" class="amount"><span>￥</span>{{item.reduceMoney}}</span>
                    <span v-if="item.couponType==2" class="amount">{{item.discount}}折</span>
                    <span>{{item.description}}</span>
                </div>
                <div class="item_right">
                    <div class="top">
                        <span v-if="item.couponType==1">满减券</span>
                        <span v-if="item.couponType==2" class="active">满折券</span>
                        全品类通用
                    </div>
                    <div class="date">有效期：</div>
                    <div class="date">{{item.startTime}}~{{item.endTime}}</div>
                </div>
                <div class="btn_box" v-if="activeName == '0'" @click="goOrder">立即使用</div>
                <div class="tag_box" v-else>
                    <img v-if="activeName == '1'" src="@/assets/images/personal/coupon_ysy.svg" alt="">
                    <img v-else src="@/assets/images/personal/coupon_ysx.svg" alt="">
                </div>
            </div>
        </div>
        <div class="nodata_box" v-else>
            <img src="@/assets/images/index/nodata.png" alt="">
            <span v-if="activeName == '0'">暂无可用优惠券哦~</span>
            <span v-if="activeName == '1'">暂无已使用优惠券哦~</span>
            <span v-if="activeName == '2'">暂无已失效优惠券哦~</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyCoupon',
    data() {
        return {
            loading: false,
            activeName: '0',
            couponList: [],
        };
    },
    created() {

    },
    mounted() {
        this.getCustomerList()
    },
    methods: {
        goCouponDetail(){
        //   window.location.href = process.env.VUE_APP_TZURL + '/getcoupon/index'
        this.$router.push('/coupon')
        },
        goOrder(){
          this.$router.push('/onlineOrder')
        },
        // 切换类型
        handleClick(val) {
            console.log(val)
            this.getCustomerList()
        },
        // 获取列表
        getCustomerList() {
            this.loading = true
            this.$api.getCouponList({state:+this.activeName}).then((res) => {
              this.loading = false
              if (res.data.code === 200) {
                this.couponList = res.data.data?res.data.data:[]
              }
            });
        },
    }
};
</script>
<style lang="scss" scoped>
.coupon_btn {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #1a9dff;
    padding: 2px 3px;
    font-size: 14px;
    color: #1a9dff;
    position: absolute;

    right: 0;
    top: 8px;
    cursor: pointer;
    &:hover{
      border: 1px solid #337CFF;
      color: #337CFF;
    }
}
.customer_box {
    position: relative;

    .list_box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .list_item {
            position: relative;
            width: 244px;
            height: 74px;
            display: flex;
            align-items: flex-start;
            margin: 0 14px 14px 0;
            background: url('~@/assets/images/orderInfo/special.png') no-repeat;
            background-size: 100% 100%;
            font-size: 10px;
            color: #ffffff;

            &.disabled {
                background: url('~@/assets/images/orderInfo/no-special.png')
                    no-repeat;
                background-size: 100% 100%;
                color: #ff8c8c;

                .date {
                    color: #fe9e9e;
                }
            }

            &:nth-of-type(4n) {
                margin-right: 0;
            }

            .btn_box {
                position: absolute;
                top: 30px;
                right: 11px;
                width: 35px;
                height: 13px;
                line-height: 13px;
                text-align: center;
                border-radius: 100px 100px 100px 100px;
                border: 1px solid #f2b2bb;
                font-weight: bold;
                font-size: 7px;
                color: #ffe6e9;
                cursor: pointer;
            }

            .tag_box {
                position: absolute;
                top: 0;
                right: 0;
            }

            .item_left {
                flex-shrink: 0;
                width: 79px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .amount {
                    padding: 11px 0 4px;
                    font-size: 23px;

                    span {
                        font-size: 9px;
                    }
                }
            }
            .item_right {
                padding: 9px 0 0 10px;

                .top {
                    font-size: 14px;
                    margin-bottom: 2px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;

                    span {
                        display: inline-block;
                        width: 28px;
                        height: 12px;
                        line-height: 12px;
                        text-align: center;
                        border: 1px solid #feeac3;
                        border-radius: 2px;
                        margin-right: 7px;
                        font-weight: bold;
                        font-size: 7px;
                        color: #ffe7b7;

                        &.active {
                            color: #ffbfc6;
                            border-color: #ffbfc6;
                        }
                    }
                }
            }
        }
    }
}
</style>